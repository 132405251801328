import Awesomplete from 'awesomplete';
import Axios from 'axios';

const input = document.querySelector('#agent-autocomplete');

if (input) {
    const awesomplete = new Awesomplete(input, {
        minChars: 1,
        filter: function() {
            return true;
        },
        sort: false,
        item: function(text, input, itemId) {
            const element = document.createElement('li');
            element.innerHTML = text;
            element.setAttribute('aria-selected', 'false');
            element.setAttribute('id', 'awesomplete_list_' + this.count + '_item_' + itemId);
            return element;
        },
    });
    input.addEventListener('keyup', (e) => {
        if ([13, 27, 37, 38, 39, 40].indexOf(e.keyCode) !== -1) {
            return;
        }

        if (input.value.length > 0) {
            const url = '/agents/list?term=' + encodeURIComponent(input.value);
            Axios.get(url)
                .then(function(response) {
                    awesomplete.list = response.data.data.map((row) => ({
                        value: row.id,
                        label: row.description,
                    }));
                });

            delete input.dataset.value;
        }
    });

    input.addEventListener('awesomplete-selectcomplete', function(e) {
        input.value = e.text.label;
        input.dataset.value = e.text.value;
    });
}
