import List from 'list.js';
import { filterableTable } from './filterable_table';

const initTrainingCourseTable = () => {
    const pageData = window.__PAGE_DATA__;

    const options = {
        valueNames: [
            'id',
            'active',
            'type',
            'title',
            'date',
            'time',
            'location',
            'count_subscribers',
            'max_subscribers',
            'created_at',
            { name: 'show_url', attr: 'href' },
        ],
        item: (values) => `
            <tr>                
                <td class="id"></td>
                <td class="has-text-centered">${values.active ?
        '<i class="fa fa-check" />' :
        '<i class="fa fa-times" />'
}</td>
                <td class="type"></td>                
                <td><strong>${values.title}</strong></td>                
                <td><span class="tag is-small is-light">${values.date}<span></td>
                <td>
                    <span class="tag is-small is-light">
                        ${values.time} ${values.time_end ? `- ${values.time_end}` : ''}
                    </span>
                </td>
                <td class="location"></td>
                <td >${values.count_subscribers}/${values.max_subscribers}</td>
                <td class="created_at"></td>
                <td class="action has-text-right nowrap">                    

                    <a class="button action_show_url is-link is-light is-small" 
                        href="${values.subscribers_url}">
                        <span class="icon">
                            <i class="fas fa-users"></i>
                        </span>
                    </a>                                  

                    <a class="button action_show_url is-warning is-light is-small" 
                        href="${values.permissions_url}">
                        <span class="icon">
                            <i class="fas fa-user-shield"></i>
                        </span>
                    </a> 
                    
                    <a class="button action_show_url is-link is-small" href="${values.url}">
                        <span class="icon">
                            <i class="fas fa-edit"></i>
                        </span>
                    </a>  
                    <form
                        action="${values.delete_url}"
                        method="POST" class="is-inline"
                        onsubmit="return confirm('${pageData.lang_delete_confirmation}');">
                        <input type="hidden" name="_method" value="delete">
                        <input type="hidden" name="_token" value="${pageData.csrfToken}" />

                        <button type="submit" class="button is-link is-small is-light is-danger">
                            <span class="icon">
                                <i class="fas fa-trash"></i>
                            </span>                                
                        </button>
                    </form>
                </td>
            </tr>
        `,
    };

    const list = new List('training-courses-table', options);

    const table = filterableTable(list, '/mycfe/admin/training-courses/list');

    table.addFilter({
        name: 'term',
        className: 'training-course-search',
    });
};

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('training-courses-table')) {
        initTrainingCourseTable();
    }
});
