
export const serializeObj = (obj) => {
    const str = [];
    for (const p in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, p) && obj[p]) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
    }
    return str.join('&');
};


export const deserializeObj = (uriStr) => {
    if (!uriStr) return {};

    if (uriStr[0] === '?') {
        uriStr = uriStr.substr(1);
    }

    const resultObj = {};
    const kvPairs = uriStr.split('+').join(' ').split('&');
    let i = 0;
    const length = kvPairs.length;
    let isArray;
    let kvPair;
    let key;
    let val;

    if (uriStr) {
        for (i; i < length; i++) {
            isArray = false;
            kvPair = kvPairs[i].split('=');
            key = decodeURIComponent(kvPair[0]);
            val = decodeURIComponent(kvPair[1]);

            if (key.indexOf('[]') > -1) {
                key = key.replace('[]', '');
                isArray = true;
            }

            if (resultObj[key] === undefined) {
                isArray ? resultObj[key] = [val] : resultObj[key] = val;
            } else if (isArray) {
                resultObj[key].push(val);
            }
        }
    }

    return resultObj;
};
