const checkValidation = (form, occupation) => {
    ['province', 'address', 'city', 'zip_code'].forEach((field) => {
        const input = form.querySelector(`[name="${field}"]`);

        const label = input.closest('.field').querySelector('.label');

        if (occupation === 'private') {
            if (input.hasAttribute('required')) {
                input.removeAttribute('required');
                label.innerHTML = label.innerHTML.slice(0, -2);
            }
        } else {
            if (!input.hasAttribute('required')) {
                input.setAttribute('required', 'true');
                label.innerHTML += ' *';
            }
        }
    });
};

export const attach = () => {
    document.addEventListener('DOMContentLoaded', () => {
        const form = document.getElementById('registration-form');

        if (!form) return;

        const occupationSelect = form.querySelector('[name="occupation"]');

        checkValidation(form, occupationSelect.value);

        occupationSelect.addEventListener('change', (e) => {
            checkValidation(form, e.target.value);
        });
    });
};
