import List from 'list.js';
import { filterableTable } from './filterable_table';

const tableId = 'training-courses-permissions-table';

const initTrainingPermissionsTable = (courseId) => {
    const pageData = window.__PAGE_DATA__;

    const options = {
        valueNames: [
            'name',
            'subscribed_by',
            'invite_notification_sent_at',
            { name: 'delete_url', attr: 'href' },
        ],
        item: (values) => `
            <tr>                
                <td>
                    ${values.name} 
                    ${values.company_name && values.name !== values.company_name ? ` - ${values.company_name}` : ''}
                    <br/>
                    <small>${values.email}</small>
                </td>                                
                <td class="invite_notification_sent_at"></td>
                <td class="action has-text-right nowrap">                                                   
                    <form
                        action="${values.delete_url}"
                        method="POST" class="is-inline"
                        onsubmit="return confirm('${pageData.lang_delete_confirmation}');">
                        <input type="hidden" name="_method" value="delete">
                        <input type="hidden" name="_token" value="${pageData.csrfToken}" />

                        <button type="submit" class="button is-link is-small is-light m-1 is-danger">
                            <span class="icon">
                                <i class="fas fa-trash"></i>
                            </span>                                
                        </button>
                    </form>                        
                </td>
            </tr>
        `,
    };

    const list = new List(tableId, options);

    const table = filterableTable(
        list,
        `/mycfe/admin/training-course/${courseId}/permissions/list`,
        {},
        { page: 1 },
        { nsPrefix: 'tcp-' },
    );

    table.addFilter({
        name: 'term',
        className: 'training-course-invited-search',
    });
};

document.addEventListener('DOMContentLoaded', () => {
    const tableElem = document.getElementById(tableId);
    if (tableElem) {
        const courseId = tableElem.dataset.id;
        if (courseId) {
            initTrainingPermissionsTable(courseId);
        }
    }
});
