
const passwordToggler = () => {
    const passwordToggles = document.querySelectorAll('.has-password-visibility');
    for (let i = 0; i < passwordToggles.length; i++) {
        const field = passwordToggles[i].querySelector('[type="password"]');
        const toggler = passwordToggles[i].querySelector('.icon.is-right');
        if (toggler && field) {
            toggler.addEventListener('click', (e) => {
                e.currentTarget.children[0].classList.toggle('fa-eye');
                e.currentTarget.children[0].classList.toggle('fa-eye-slash');
                field.type = field.type === 'text' ? 'password' : 'text';
            });
        }
    }
};

export const attach = () => {
    document.addEventListener('DOMContentLoaded', function() {
        passwordToggler();
    });
};
