import List from 'list.js';
import BulmaModal from '../bulma_modal';
import { first } from 'lodash';
import { filterableTable } from './filterable_table';

const initSelectClientTable = () => {
    const form = document.getElementById('client-select-form');
    const modalElem = document.getElementById('cart-client-select-modal');
    const modal = new BulmaModal(modalElem);

    const bindModal = () => {
        const showModal = (e) => {
            const btn = e.currentTarget;
            const selectedRow = btn.closest('tr');
            const clientId = selectedRow.querySelector('[name="client_id"]');
            form.client_id.value = clientId.value;
            const companyName = selectedRow.querySelector('.company_name').innerText;
            const modalTitle = modalElem.querySelector('.client-selected-title');
            modalTitle.innerText = window.__PAGE_DATA__.modal_title_placeholder
                .replace('__selected_client__', companyName);
            modal.show();
        };

        document.querySelectorAll('.client-select-form-button')
            .forEach((el) => el.addEventListener('click', showModal));
    };

    const data = window.__PAGE_DATA__;

    const options = {
        valueNames: ['id', 'branch', 'code', 'company_name', 'full_address', 'agent'],
        item: (values) => `
            <tr class="${data.client_id == values.id ? 'is-selected' : ''}">
                <td class="code">${values.code}</td>
                <td class="branch">${values.branch}</td>
                <td class="company_name">${values.company_name}</td>
                <td class="full_address">${values.full_address}</td>
                <td class="agent">${values.agent}</td>
                <td class="action has-text-right">
                    <input type="hidden" name="client_id" value="${values.id}">
                    <button
                        type="button"
                        class="button client-select-form-button is-small
                            ${data.client_id == values.id ? 'is-light' : 'is-primary'}"
                    >
                        ${data.lang_select}
                    </button>
                </td>
            </tr>
    `,
    };

    const clientsList = new List('select-clients-table', options);

    const table = filterableTable(clientsList, '/clients/cart-list', {
        onUpdate: () => bindModal(),
    });

    table.addFilter({
        name: 'term',
        className: 'client-search',
    });

    table.addFilter({
        name: 'agent_id',
        className: 'agent-search',
        onPrepareSearch: (e, searchState, filters) => {
            const searchByProvinceSelect = filters.province_id;

            if (searchState.province_id && searchState.agent_id) {
                searchState.province_id = '';
                searchByProvinceSelect.value = '';
            }
        },
        onSearchCompleted: (request, searchState, filters) => {
            const searchByProvinceSelect = filters.province_id;

            request
                .then(({ data }) => {
                    const firstOption = first(searchByProvinceSelect.getElementsByTagName('option'));
                    searchByProvinceSelect.innerHTML = '';
                    searchByProvinceSelect.appendChild(firstOption);

                    data.filters.provinces.forEach((province) => {
                        const option = document.createElement('option');
                        option.innerText = `${province.description} (${province.code})`;
                        option.value = province.id;
                        option.selected = parseInt(searchState.province_id) === parseInt(province.id);
                        searchByProvinceSelect.append(option);
                    });
                });
        },
    });

    table.addFilter({
        name: 'province_id',
        className: 'province-search',
    });

    bindModal();
};

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('select-clients-table')) {
        initSelectClientTable();
    }
});
