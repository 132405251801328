import List from 'list.js';
import { filterableTable } from './filterable_table';

export const initAssociableUsersTable = (userType) => {
    const initState = window.__INITIAL_STATE__;
    const pageData = window.__PAGE_DATA__;

    const options = {
        valueNames: ['id', 'email', 'name', 'surname', 'company_name', 'type', { name: 'url', attr: 'href' }],
        item: (values) => `
            <tr>
                <td class="id">${values.id}</td>
                <td class="email">${values.email}</td>                
                <td class="name">${values.name}</td>
                <td class="surname">${values.surname}</td>
                <td class="company_name">${values.company_name}</td>
                <td class="type">${values.type}</td>
                <td class="action has-text-right">
                    <form action="${pageData.form_action}" method="post" class="is-inline">
                        <button type="submit" class="button is-primary is-small">
                            ${pageData.lang_associate}
                        </button>       
                        <input type="hidden" name="user" value="${values.id}" />               
                        <input type="hidden" name="_token" value="${initState.csrfToken}" />                         
                    </form>                    
                </td>
            </tr>
        `,
    };

    const usersList = new List(`${userType}-users-table`, options);

    const table = filterableTable(usersList, '/users/list', {}, {
        page: 1,
        type: userType,
    });

    table.addFilter({
        name: 'term',
        className: 'user-search',
    });

    table.addFilter({
        name: 'type',
        className: 'type-search',
    });
};
