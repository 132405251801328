import { Chart, BarController, BarElement, LinearScale, CategoryScale, Tooltip } from 'chart.js';
import { formatCurrency } from './utils/formatter';

Chart.register(BarController, BarElement, LinearScale, CategoryScale, Tooltip);

document.querySelectorAll('.target-chart').forEach(function(canvas) {
    const data = JSON.parse(canvas.dataset.data);
    const options = {
        scales: {
            y: {
                ticks: {
                    callback: function(val, index) {
                        return val + ' €';
                    },
                },
                suggestedMin: 0,
                suggestedMax: canvas.dataset.suggestedMax,
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += formatCurrency(context.parsed.y);
                        }
                        return label;
                    },
                },
            },
        },
    };

    new Chart(canvas, {
        type: 'bar',
        data: data,
        options: options,
    });
});
