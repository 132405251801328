import List from 'list.js';
import { filterableTable } from './filterable_table';

const tableId = 'training-courses-subscribers-table';

const initTrainingCourseTable = (courseId) => {
    const pageData = window.__PAGE_DATA__;

    const options = {
        valueNames: [
            'name',
            'surname',
            'user',
            'company_name',
            'email',
            'phone',
            'subscribed_by',
            'created_at',
            { name: 'delete_url', attr: 'href' },
        ],
        item: (values) => `
            <tr>                
                <td class="name"></td>                
                <td class="surname"></td>
                <td class="user"></td>
                <td class="company_name"></td>
                <td class="email"></td>
                <td class="phone"></td>
                <td class="subscribed_by"></td>
                <td class="created_at"></td>
                <td class="action has-text-right nowrap">
                    ${
    values.is_main_subscriber ?

        `
                    <a class="button is-link is-small is-light m-1 is-info" href="${values.view_url}">
                        <span class="icon">
                            <i class="fas fa-eye"></i>
                        </span>                                
                    </a>
                    <form
                            action="${values.delete_url}"
                            method="POST" class="is-inline"
                            onsubmit="return confirm('${pageData.lang_delete_confirmation}');">
                            <input type="hidden" name="_method" value="delete">
                            <input type="hidden" name="_token" value="${pageData.csrfToken}" />

                            <button type="submit" class="button is-link is-small is-light m-1 is-danger">
                                <span class="icon">
                                    <i class="fas fa-trash"></i>
                                </span>                                
                            </button>
                        </form>
                        ` :
        ''
}
                </td>
            </tr>
        `,
    };

    const list = new List(tableId, options);

    const table = filterableTable(
        list,
        `/mycfe/admin/training-course/${courseId}/subscribers/list`,
    );

    table.addFilter({
        name: 'term',
        className: 'training-course-search',
    });
};

document.addEventListener('DOMContentLoaded', () => {
    const tableElem = document.getElementById(tableId);
    if (tableElem) {
        const courseId = tableElem.dataset.id;
        if (courseId) {
            initTrainingCourseTable(courseId);
        }
    }
});
