import _ from 'lodash';

const SUPPORTED_INPUTS = ['INPUT', 'TEXTAREA'];

const validator = (form) => {
    const validationState = new Set();

    function validationMsg(validationData) {
        const { inputProps, action } = validationData;

        const elementsValidationMsg = inputProps.closest('.field').querySelectorAll('p.help');
        if (elementsValidationMsg.length === 1) {
            const validationMsgClasses = elementsValidationMsg[0].classList;
            action === 'hide' ? validationMsgClasses.add('is-hidden') : validationMsgClasses.remove('is-hidden');
        }
        action === 'hide' ? inputProps.classList.remove('is-danger') : inputProps.classList.add('is-danger');
    }

    function validationRules() {
        return {
            pattern: (inputProps, pattern) => {
                const inputValue = inputProps.value;
                const inputName = inputProps.name;
                const isInputValid = new RegExp(`^${pattern}$`, 'g').test(inputValue);
                isInputValid ? getState().removeFromState({ inputProps, inputName }) :
                    getState().addToState({ inputProps, inputName });
            },
            required() {
                const formInputElems = [...form.elements]
                    .filter((item) => _.includes(SUPPORTED_INPUTS, item.nodeName) && item.dataset.validationRequired);
                let valid = true;
                for (const inputProps of formInputElems) {
                    const inputName = inputProps.name;
                    const inputValue = inputProps.value;

                    if (!inputValue) {
                        getState().addToState({ inputProps, inputName });
                        valid = false;
                    }
                }
                return valid;
            },
        };
    }

    function validateForm(inputProps) {
        const dataset = inputProps.dataset;

        const rules = validationRules();

        return _.map(dataset, (value, key) => {
            const rule = key.replace('validation', '').toLowerCase();
            if (rules[rule]) {
                return rules[rule](inputProps, value);
            }
            return null;
        });
    }

    function getState() {
        return {
            addToState: (inputData) => {
                const { inputProps, inputName } = inputData;
                validationState.add(inputName);
                validationMsg({ inputProps });
            },
            removeFromState: (inputData) => {
                const action = 'hide';
                const { inputProps, inputName } = inputData;
                validationState.delete(inputName);
                validationMsg({ inputProps, action });
            },
            validateState: () => validationState.size === 0 ? validationRules().required() : false,
        };
    }

    function attachKeyUpEvent() {
        form.addEventListener('keyup', function(event) {
            const nodeName = event.target.nodeName;
            const inputProps = event.target;

            if (_.includes(SUPPORTED_INPUTS, nodeName)) {
                validateForm(inputProps);
            }
        });
    }

    attachKeyUpEvent();

    form.addEventListener('submit', function(e) {
        const isValid = getState().validateState();
        if (!isValid) e.preventDefault();
    });
};

export const attachValidationToForm = (form) => validator(form);

export const attach = () => {
    document.addEventListener('DOMContentLoaded', () => {
        document.querySelectorAll('form.cfe-validation').forEach((form) => validator(form));
        (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
            const $notification = $delete.parentNode;

            $delete.addEventListener('click', () => {
                if ($notification.classList.contains('is-ajax')) {
                    $notification.classList.add('is-hidden');
                } else {
                    $notification.parentNode.removeChild($notification);
                }
            });
        });
    });
};


