import BulmaModal from './bulma_modal';

document.querySelectorAll('#catalog-menu .menu-list.root > .menu-item > a')
    .forEach(function(item) {
        item.addEventListener('click', function(e) {
            e.preventDefault();
            item.parentElement.classList.toggle('selected');
        });
    });

document.querySelectorAll('#catalog-cart-form .catalog-multiple-select-button')
    .forEach(function(item) {
        item.addEventListener('click', function(e) {
            document.querySelectorAll('#catalog-cart-form input[type="checkbox"]').forEach(function(item) {
                item.checked = false;
            });

            document.querySelectorAll('.catalog-multiple-select-control').forEach(function(item) {
                item.classList.toggle('is-hidden');
            });
        });
    });

document.querySelectorAll('#catalog-cart-form .quantity-selector').forEach(function(group) {
    const quantityValue = group.querySelector('.quantity-value');
    const formQuantityValue = group.querySelector('input.quantity-hidden-input');
    const editableQuantity = group.querySelector('[name="quantity"]');

    group.querySelector('.popover-trigger-confirm').addEventListener('click', function(e) {
        e.preventDefault();
        quantityValue.textContent = editableQuantity.value.toString();
        formQuantityValue.value = parseInt(editableQuantity.value);
        group.querySelector('.popover').classList.remove('is-popover-active');
    });

    group.querySelector('.quantity-plus').addEventListener('click', function(e) {
        e.preventDefault();
        let currentValue = parseInt(quantityValue.textContent.trim());
        currentValue += 1;
        quantityValue.textContent = currentValue.toString();
        formQuantityValue.value = currentValue;
        editableQuantity.value = currentValue;
    });

    group.querySelector('.quantity-minus').addEventListener('click', function(e) {
        e.preventDefault();
        let currentValue = parseInt(quantityValue.textContent.trim());
        if (currentValue < 2) {
            return;
        }
        currentValue -= 1;
        quantityValue.textContent = currentValue.toString();
        formQuantityValue.value = currentValue;
        editableQuantity.value = currentValue;
    });
});

document.querySelectorAll('#catalog-cart-form .catalog-single-add-to-cart-button').forEach(function(item) {
    item.addEventListener('click', function(e) {
        const form = document.querySelector('#catalog-cart-single-form');
        const container = item.closest('.catalog-item');
        const idElement = container.querySelector('.id-hidden-input');
        const quantityElement = container.querySelector('.quantity-hidden-input');

        form.querySelector('.id-hidden-input').value = idElement.value;
        form.querySelector('.quantity-hidden-input').value = quantityElement.value;

        form.submit();
    });
});

document.querySelectorAll('#catalog-cart-form input[type="checkbox"]').forEach(function(item) {
    item.addEventListener('change', function(e) {
        document.querySelector('#catalog-multiple-add-to-cart-button').disabled =
            document.querySelectorAll('#catalog-cart-form input[type="checkbox"]:checked').count === 0;
    });
});

const filteredItemDetailedImageElement = document.querySelector('#filtered-item-detailed-image');
if (filteredItemDetailedImageElement) {
    const filteredItemDetailedImageModal = new BulmaModal(filteredItemDetailedImageElement, true);
    document.querySelectorAll('.filtered-item .filtered-item-image.has-detail').forEach(function(item) {
        item.addEventListener('click', function(e) {
            const figure = filteredItemDetailedImageElement.querySelector('figure');
            figure.innerHTML = '';
            const image = document.createElement('img');
            image.src = item.dataset.detailedImageUrl;
            figure.append(image);
            filteredItemDetailedImageModal.show();
        });
    });
}
