
const cardToggler = () => {
    const cardToggles = document.querySelectorAll('.card-toggle .card-toggler');
    for (let i = 0; i < cardToggles.length; i++) {
        cardToggles[i].addEventListener('click', (e) => {
            const cardContent = e.currentTarget.closest('.card').querySelectorAll('.card-content')[0];
            cardContent.classList.toggle('is-hidden');
        });
    }
};

export const attach = () => {
    document.addEventListener('DOMContentLoaded', function() {
        cardToggler();
    });
};
