import List from 'list.js';
import { filterableTable } from './filterable_table';

const initQuotationTable = () => {
    const pageData = window.__INITIAL_STATE__;

    const options = {
        valueNames: [
            'id',
            'full_number',
            'created_at',
            'company_name',
            'client_reference',
            'gross_total',
            'can_send',
            'can_prices',
            'can_discounts',
            'can_download_xls',
            'can_destroy',
            'can_restore',
            { name: 'show_url', attr: 'href' },
            { name: 'pdf_url', attr: 'href' },
            { name: 'send_url', attr: 'href' },
            { name: 'cart_url', attr: 'action' },
            { name: 'download_xls_url', attr: 'href' },
            { name: 'destroy_url', attr: 'action' },
            { name: 'restore_url', attr: 'action' },
        ],
        item: (values) => `
            <tr>
                <td style="${values.trashed ? 'text-decoration: line-through;' : ''}">
                    <a class="show_url full_number" href=""></a>
                </td>
                <td class="created_at"></td>
                <td class="company_name"></td>
                <td class="client_reference"></td>
                ${values.can_prices ? `
                    <td class="gross_total"></td>
                ` : ''}
                <td class="has-text-right nowrap">
                    <a class="show_url link is-small mr-1" href="${values.show_url}">
                        <span class="icon"><img src="/build/images/edit.svg" /></span>
                    </a>
                    ${values.can_discounts ? `
                        <a class="pdf_url link is-small mr-1" target="_blank"
                            href="">
                            <span class="icon"><img src="/build/images/pdf.svg" /></span>
                        </a>
                    `: ''}
                    ${values.can_send ? `
                        <a class="send_url link is-small mr-1" href="#">
                            <span class="icon"><img src="/build/images/email.svg" /></span>
                        </a>
                    `: ''}
                    <form action="#" method="post" class="cart_url is-inline mr-1">
                        <button type="submit" class="link is-ghost">
                            <span class="icon"><img src="/build/images/refill-cart.svg" /></span>
                        </button>
                        <input type="hidden" name="_token" value="${pageData.csrfToken}" />
                    </form>
                    ${values.can_download_xls ? `
                        <a class="download_xls_url link mr-1" target="_blank" href="">
                            <span class="icon"><img src="/build/images/excel.svg" /></span>
                        </a>
                    `: ''}
                    ${values.can_destroy ? `
                        <form action="" method="post" class="destroy_url is-inline">
                            <button type="submit" class="link is-ghost">
                                <span class="icon"><img src="/build/images/trash.svg" /></span>
                            </button>
                            <input type="hidden" name="_method" value="delete">
                            <input type="hidden" name="_token" value="${pageData.csrfToken}" />
                        </form>
                    `: ''}
                    ${values.can_restore ? `
                        <form action="" method="post" class="restore_url is-inline">
                            <button type="submit" class="link is-ghost">
                                <span class="icon"><img style="max-height: 24px;"
                                        src="/build/images/restore.svg" /></span>
                            </button>
                            <input type="hidden" name="_token" value="${pageData.csrfToken}" />
                        </form>
                    `: ''}
                </td>
            </tr>
        `,
    };

    const usersList = new List('quotations-table', options);

    const table = filterableTable(usersList, '/quotations/list');

    table.addFilter({
        name: 'term',
        className: 'quotation-search',
    });

    table.addDateFilter({
        name: 'created_at',
        className: 'quotation-date',
        options: { type: 'date', dateFormat: 'dd-MM-yyyy', showFooter: false },
    });
};


document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('quotations-table')) {
        initQuotationTable();
    }
});
