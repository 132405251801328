
export default class BulmaDropdown {
    static attach() {
        document.addEventListener('DOMContentLoaded', () => {
            document.querySelectorAll('.dropdown').forEach(function(element) {
                element.addEventListener('click', function(event) {
                    event.stopPropagation();
                    element.classList.toggle('is-active');
                });
            });
        });
    }
}
