import * as Sentry from '@sentry/browser';

if (import.meta.env.VITE_SENTRY_LARAVEL_DSN) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
        release: 'release',
    });

    if (window.__INITIAL_STATE__) {
        const user = window.__INITIAL_STATE__.user;

        if (user) {
            Sentry.setUser({ email: user.email, id: user.id });
        }
    }
}
