import List from 'list.js';
import { filterableTable } from './filterable_table';

const getNameColumn = (values) => {
    if (values.name && values.surname) {
        return `${values.name} ${values.surname} <br/><small>${values.email}</small>`;
    } else {
        return values.email;
    }
};

const initUserTable = () => {
    let selectedUsersIds = [];

    const selectedUsersInput = document.getElementsByName('selected_users')[0];
    const selectedUsersConditionsInput = document.getElementsByName('selected_users_conditions')[0];

    const options = {
        valueNames: [
            { name: 'id' },
            'email',
            'name',
            'surname',
            'company_name',
            'type',
            { name: 'url', attr: 'href' },
        ],
        item: (values) => {
            return `
                <tr>
                    <td>
                        <input class="user-checkbox" type="checkbox" ${values.checked ? 'checked' : ''
}  value="${values.id}" />
                    </td>
                    <td>
                        ${getNameColumn(values)}
                    </td>                                
                    <td class="company_name">${values.company_name}</td>
                    <td class="type">${values.type
}</td>                                                
                </tr>
            `;
        },
    };

    const usersList = new List(
        'training-cousers-users-selection-table',
        options,
    );

    const table = filterableTable(usersList, '/users/list', {
        onUpdate: (data, filters) => {
            document.querySelectorAll('.user-checkbox').forEach((user) => {
                user.addEventListener('click', (e) => {
                    const value = parseInt(e.target.value);
                    if (e.target.checked) {
                        selectUser(value);
                    } else {
                        deselectUser(value);
                    }

                    document.getElementById('selected-users-counter').innerHTML = `(${selectedUsersIds.length})`;
                    selectedUsersInput.value = JSON.stringify(selectedUsersIds);
                });
            });

            document.getElementById('table-users-counter').innerHTML = `(${data.meta.total})`;

            const activeFilters = Object.keys(filters)
                .filter((key) => key !== 'page' && filters[key] !== '')
                .reduce((acc, key) => {
                    acc[key] = filters[key];
                    return acc;
                }, {});

            selectedUsersConditionsInput.value = JSON.stringify(activeFilters);
        },
        onAddRow: (list, row) => {
            list.add({
                ...row,
                checked: selectedUsersIds.includes(row.id) ? true : false,
            });
        },
    });

    table.addFilter({
        name: 'term',
        className: 'user-search',
    });

    table.addFilter({
        name: 'type',
        className: 'type-search',
    });

    table.addFilter({
        name: 'agent',
        className: 'agent-search',
    });

    table.addFilter({
        name: 'purchase-group',
        className: 'purchase-group-search',
    });

    function selectUser(id) {
        if (!selectedUsersIds.includes(id)) {
            selectedUsersIds.push(id);
        }
    }

    function deselectUser(id) {
        if (selectedUsersIds.includes(id)) {
            selectedUsersIds.splice(selectedUsersIds.indexOf(id), 1);
        }
    }

    document.getElementById('training-course-permissions-cancel-selection').addEventListener('click', () => {
        selectedUsersIds = [];
        document.querySelectorAll('.user-checkbox').forEach((checkbox) => {
            checkbox.checked = false;
        });
        document.getElementById('selected-users-counter').innerHTML = `(0)`;
    });
};

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('training-cousers-users-selection-table')) {
        initUserTable();
    }
});
