
const drawerToggler = () => {
    const drawerTogglers = document.querySelectorAll('.drawer-btn');

    const toggle = (_target) => {
        const isVisible = _target.classList.toggle('cfe-drawer-open');
        const wrapper = _target.querySelector('.cfe-drawer-content-wrapper');

        wrapper.style.transform = !isVisible ? 'translateX(100%)' : 'none';
    };

    for (let i = 0; i < drawerTogglers.length; i++) {
        drawerTogglers[i].addEventListener('click', (e) => {
            e.preventDefault();
            const target = e.currentTarget.dataset.target;
            const $target = document.getElementById(target);
            toggle($target);
        });
    }

    document.querySelectorAll('.cfe-drawer-mask')
        .forEach((el) => el.addEventListener('click', (ev) => toggle(ev.target.closest('.cfe-drawer'))));

    document.querySelector('.close-drawer')
        .addEventListener('click', (ev) => toggle(ev.target.closest('.cfe-drawer')));
};


export const attach = () => {
    document.addEventListener('DOMContentLoaded', function() {
        drawerToggler();
    });
};
