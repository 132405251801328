import List from 'list.js';
import { filterableTable } from './filterable_table';

const initAgentTable = () => {
    const options = {
        valueNames: ['code', 'company_name', { name: 'url', attr: 'href' }],
        item: (values) => `
            <tr>
                <td class="code" style="${!values.active ? 'text-decoration: line-through;' : ''}">${values.code}</td>
                <td class="company_name">${values.company_name}</td>
                <td class="action has-text-right">
                    <a class="url" href="${values.url}">
                        <span class="icon"><i class="far fa-file"></i></span>
                    </a>
                </td>
            </tr>
        `,
    };

    const agentsList = new List('agents-table', options);

    const table = filterableTable(agentsList, '/agents/list');

    table.addFilter({
        name: 'term',
        className: 'agent-search',
    });
};


document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('agents-table')) {
        initAgentTable();
    }
});
