/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import './support.js';
import ProductFilters from './product_filters';
import BurgerMenu from './burger_menu';
import BulmaDropdown from './bulma_dropdown';
import * as bulmaCard from './bulma_card';
import * as bulmaCaledar from './bulma_calendar';
import * as bulmaInputs from './bulma_inputs';
import * as drawer from './drawer';
import * as popover from './popover';
import * as validator from './validation';
import * as sliders from './sliders';
import * as registration from './registration';
import * as mycfe from './mycfe';

import './sentry';
import './bootstrap';
import './search_autocomplete';
import './agent_autocomplete';
import './client_autocomplete';
import './cart';
import './catalog';
import './agent_targets';
import './agent_deadlines';
import './tables/clients_table';
import './tables/select_client_table';
import './client_stats';
import './tables/users_table';
import './tables/training_courses_users_selection_table';
import './tables/agent_users_table';
import './tables/client_users_table';
import './tables/private_users_table';
import './tables/agents_table';
import './tables/orders_table';
import './tables/quotations_table';
import './tables/training_courses_table.js';
import './tables/published_training_courses_table.js';
import './tables/training_courses_subscribers_table.js';
import './tables/training_courses_public_subscribers_table.js';
import './tables/training_courses_permissions_table.js';


BurgerMenu.attach();
BulmaDropdown.attach();
ProductFilters.attach();
bulmaCard.attach();
bulmaCaledar.attach();
bulmaInputs.attach();
validator.attach();
drawer.attach();
popover.attach();
sliders.attach();
registration.attach();
mycfe.attach();
