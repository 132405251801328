import BulmaModal from './bulma_modal';

class ProductFilter {
    constructor(element, order) {
        this.element = element;
        this.values = [];
        this.radioButtons = this.element.querySelectorAll('.is-attribute');
        this.order = order;

        const that = this;

        this.radioButtons.forEach(function(radioButton) {
            if (that.attributeType === undefined) {
                that.attributeType = radioButton.dataset.attributeType;
            }
            if (that.rawDefaultValue === undefined) {
                that.rawDefaultValue = radioButton.dataset.defaultValue;
            }
            if (that.name === undefined) {
                that.name = radioButton.name;
            }

            that.values.push(that.parseValue(radioButton.value));
        });

        this.setupModal();
    }

    setupModal() {
        const helpLink = this.element.querySelector('.help-link');
        const modalElement = this.element.querySelector('.help-modal');

        if (helpLink && modalElement) {
            this.modal = new BulmaModal(modalElement);

            const that = this;
            helpLink.addEventListener('click', function() {
                that.modal.show();
            });
        }
    }

    isHidden() {
        return this.element.classList.contains('is-hidden');
    }

    isSelected() {
        return this.selectedValue() !== null;
    }

    selectedValue() {
        const selectedElement = this.element.querySelector('.is-attribute:checked');
        return selectedElement ? this.parseValue(selectedElement.value) : null;
    }

    parseValue(rawValue) {
        if (rawValue === null) {
            return null;
        }

        let value;
        if (this.attributeType === 'boolean') {
            value = rawValue === '1';
        } else if (this.attributeType === 'integer') {
            value = parseInt(rawValue);
        } else {
            value = rawValue;
        }

        return value;
    }

    defaultValue() {
        if (this.rawDefaultValue === undefined) {
            return null;
        }

        return this.parseValue(this.rawDefaultValue);
    }

    reset() {
        const radioButton = this.element.querySelector('.is-attribute:checked');
        if (radioButton) {
            radioButton.checked = false;
        }
    }

    toggle(isVisible) {
        this.element.classList.toggle('is-hidden', !isVisible);
    }

    showAttributeValues(values) {
        const that = this;
        const defaultValue = this.defaultValue();

        this.radioButtons.forEach(function(radioButton) {
            const toggle = !(values.indexOf(that.parseValue(radioButton.value)) !== -1);
            radioButton.parentElement.classList.toggle('is-hidden', toggle);
            if (that.parseValue(radioButton.value) === defaultValue) {
                radioButton.checked = true;
            }
        });

        const selectedRadio = this.element.querySelector('.is-attribute:checked');
        if (selectedRadio) {
            selectedRadio.dispatchEvent(new Event('change'));
        }
    }
}

class ProductList {
    constructor(element, itemSelector) {
        this.element = element;
        this.itemSelector = itemSelector;
        this.matches = 0;
        this.counter = document.querySelector('.items-counter');
    }

    filterRows(selectedAttributes) {
        this.matches = 0;
        this.element.querySelectorAll(this.itemSelector).forEach((tr) => {
            const match = this.rowMatches(tr, selectedAttributes);
            tr.classList.toggle('is-hidden', !match);
            if (match) {
                this.matches++;
            }
        });
        return this;
    }

    filteredRows() {
        return this.element.querySelectorAll(this.itemSelector+':not(.is-hidden)');
    }

    distinctAttributeValueForRows(filterName) {
        const values = [];

        this.filteredRows().forEach(function(tr) {
            const attribute = JSON.parse(tr.dataset.attributes).filter(function(a) {
                return a.name === filterName;
            })[0];

            // value may be an array so it is stringified to make a quick comparison
            // TODO: improve to handle the case of same values with different order
            if (attribute && !values.includes(JSON.stringify(attribute.value))) {
                values.push(JSON.stringify(attribute.value));
            }
        });

        // Show the filter only if there is more than one value for it in the result set.
        // Multiple options in the filter may and up in the same multiple options for each row
        // See multiple wall widths for plasterboard kits
        return values.length;
    }

    updateCounter() {
        if (this.counter) {
            this.counter.innerHTML = this.matches;
        }
    }

    rowMatches(tr, selectedAttributes) {
        const that = this;
        let rowMatches = true;
        const productAttributes = JSON.parse(tr.dataset.attributes);
        selectedAttributes.forEach(function(selectedAttribute) {
            rowMatches = rowMatches && that.attributeValueMatches(productAttributes, selectedAttribute);
        });

        return rowMatches;
    }

    attributeValueMatches(productAttributes, selectedAttribute) {
        let attributeMatches = false;
        if (productAttributes) {
            productAttributes.forEach(function(productAttribute) {
                if (!attributeMatches && productAttribute.name === selectedAttribute.name && (
                    (Array.isArray(productAttribute.value) &&
                        productAttribute.value.indexOf(selectedAttribute.value) !== -1) ||
                    productAttribute.value === selectedAttribute.value)) {
                    attributeMatches = true;
                }
            });
        }

        return attributeMatches;
    }

    hasAttribute(productAttributes, attributeName) {
        let attributeMatches = false;
        if (productAttributes) {
            productAttributes.forEach(function(productAttribute) {
                if (!attributeMatches && productAttribute.name === attributeName) {
                    attributeMatches = true;
                }
            });
        }

        return attributeMatches;
    }

    resultsForAttributeValue(name, value) {
        let matches = 0;
        const that = this;
        this.element.querySelectorAll(that.itemSelector + ':not(.is-hidden)').forEach(function(tr) {
            const productAttributes = JSON.parse(tr.dataset.attributes);
            if (that.attributeValueMatches(productAttributes, { 'name': name, 'value': value })) {
                matches++;
            }
        });

        return matches;
    }

    resultsForAttribute(name) {
        let matches = 0;
        const that = this;
        this.element.querySelectorAll(that.itemSelector + ':not(.is-hidden)').forEach(function(tr) {
            const productAttributes = JSON.parse(tr.dataset.attributes);
            if (that.hasAttribute(productAttributes, name)) {
                matches++;
            }
        });

        return matches;
    }
}

export default class ProductFilters {
    constructor(element) {
        const that = this;

        this.element = element;

        this.productFilters = [];
        this.element.querySelectorAll('.product-filter').forEach(function(element, key) {
            that.productFilters.push(new ProductFilter(element, key));
        });

        this.table = new ProductList(this.element.querySelector('.filtered-items-list'), '.filtered-item');

        this.productFilters.forEach(function(productFilter) {
            productFilter.radioButtons.forEach(function(radioButton) {
                radioButton.addEventListener('change', function(e) {
                    const productFilter = that.productFilterFromElement(e.target.closest('.product-filter'));
                    that.resetNextFilters(productFilter);

                    const selectedAttributes = that.selectedAttributes();
                    that.table.filterRows(selectedAttributes).updateCounter();

                    that.setFilterVisibility();
                });
            });
        });

        this.productFilters.forEach(function(filter, key) {
            if (key > 0) {
                filter.element.classList.add('is-hidden');
            }
        });
    }

    static attach() {
        document.addEventListener('DOMContentLoaded', () => {
            const element = document.querySelector('.product-filters');
            if (element) {
                ProductFilters.instance = new ProductFilters(element);
            }
        });
    }

    selectedAttributes() {
        const selectedAttributes = [];

        this.productFilters.forEach(function(productFilter) {
            if (productFilter.selectedValue() !== null) {
                selectedAttributes.push({ 'name': productFilter.name, 'value': productFilter.selectedValue() });
            }
        });

        return selectedAttributes;
    }

    nextFilter() {
        const that = this;
        let nextProductFilter = null;
        this.productFilters.forEach(function(productFilter) {
            if (nextProductFilter === null && (productFilter.element.classList.contains('is-hidden') ||
                !productFilter.element.querySelector('.is-attribute:checked')) &&
                that.table.resultsForAttribute(productFilter.name) > 0) {
                let visibleAttributeValues = 0;
                productFilter.values.forEach(function(value) {
                    if (that.table.resultsForAttributeValue(productFilter.name, value) > 0) {
                        visibleAttributeValues++;
                    }
                });

                // Show the filter only if there is more than one row for it in the result set.
                // Multiple options in the filter may and up in the same multiple options for each row
                // See multiple wall widths for plasterboard kits
                if (visibleAttributeValues > 1 && that.table.distinctAttributeValueForRows(productFilter.name) > 1) {
                    nextProductFilter = productFilter;
                }
            }
        });

        return nextProductFilter;
    }

    setFilterVisibility() {
        const that = this;
        const nextProductFilter = this.nextFilter();

        this.productFilters.forEach(function(productFilter) {
            if (productFilter.selectedValue() !== null || productFilter === nextProductFilter) {
                if (productFilter === nextProductFilter) {
                    const visibleAttributes = [];
                    productFilter.values.forEach(function(value) {
                        if (that.table.resultsForAttributeValue(productFilter.name, value) > 0) {
                            visibleAttributes.push(value);
                        }
                    });

                    productFilter.showAttributeValues(visibleAttributes);
                }

                productFilter.toggle(true);
            } else {
                productFilter.toggle(false);
            }
        });
    }

    showNextFilter() {
        const nextProductFilter = this.nextFilter();
        if (nextProductFilter) {
            nextProductFilter.element.classList.remove('is-hidden');
        }
    }

    resetNextFilters(lastSetProductFilter) {
        this.productFilters.forEach(function(productFilter) {
            if (productFilter.order > lastSetProductFilter.order) {
                productFilter.reset();
            }
        });
    }

    productFilterFromElement(element) {
        let productFilter = null;
        this.productFilters.forEach(function(filter) {
            if (filter.element === element) {
                productFilter = filter;
            }
        });

        return productFilter;
    }
}
