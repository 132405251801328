export default class BulmaModal {
    constructor(element, closeOnClick = false) {
        this.element = element;

        const that = this;
        let selector = '.modal-close, .modal-background, .modal-close-button, .modal-card-head .delete';
        if (closeOnClick) {
            selector += ', .modal-content';
        }
        this.element.querySelectorAll(selector)
            .forEach(function(e) {
                e.addEventListener('click', function() {
                    that.close();
                });
            });
    }

    show() {
        this.element.classList.toggle('is-active');
        document.querySelector('html').classList.toggle('is-clipped');
        this.onShow();
    }

    close() {
        this.element.classList.toggle('is-active');
        document.querySelector('html').classList.toggle('is-clipped');
        this.onClose();
    }

    onShow() {
        this.element.dispatchEvent(new Event('modal:show'));
    }

    onClose() {
        this.element.dispatchEvent(new Event('modal:close'));
    }
}
