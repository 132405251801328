import Player from '@vimeo/player';
import Sortable from 'sortablejs';
import Axios from 'axios';
import Choices from 'choices.js';

const initVimeoPlayers = () => {
    document.querySelectorAll('.vimeo-video').forEach((elem) => {
        const vimeoElem = elem.querySelector('.vimeo-iframe-wrapper');

        const player = new Player(vimeoElem.id);

        player.on('loaded', () => {
            elem.querySelector('.box-loader-wrapper').classList.remove(
                'is-active',
            );
        });

        player.ready().catch((e) => {
            elem.parentNode.remove();
        });
    });
};

const sectionToggler = () => {
    const sections = document.querySelectorAll('.forms-section .button');
    for (let i = 0; i < sections.length; i++) {
        sections[i].addEventListener('click', (e) => {
            const section = e.currentTarget.closest('.forms-section');
            const content = section.querySelector('.forms-list');
            content.classList.toggle('is-hidden');

            const icon = section.querySelector('.fa');
            icon.classList.toggle('fa-plus');
            icon.classList.toggle('fa-minus');
        });
    }
};

const initDragDrop = () => {
    const draggableContainer = document.querySelector(
        '.is-draggable-container',
    );
    if (draggableContainer) {
        Sortable.create(draggableContainer, {
            animation: 150,
            ghostClass: 'drag-over',
            store: {
                /**
                 * @param {Sortable}  sortable
                 */
                set: (sortable) => {
                    const sequence = sortable.toArray();

                    Axios.post(draggableContainer.dataset.url, {
                        sequence: sequence.map((id) => parseInt(id)),
                    })
                        .then((data) => {
                            document
                                .querySelector(
                                    '.notification.is-success.is-ajax',
                                )
                                .classList.remove('is-hidden');
                        })
                        .catch(() => {
                            document
                                .querySelector(
                                    '.notification.is-danger.is-ajax',
                                )
                                .classList.remove('is-hidden');
                        });
                },
            },
        });
    }
};

const initTrainingCourse = () => {
    const addPartecipantBtn = document.querySelector(
        '.training-course-subscription-form #add-partecipant-btn',
    );

    const removePartecipantBtn = document.querySelectorAll(
        '.training-course-subscription-form .remove-partecipant-btn',
    );

    if (!addPartecipantBtn || !removePartecipantBtn) {
        return null;
    }

    addPartecipantBtn.addEventListener('click', (e) => {
        e.preventDefault();
        const partecipants = document.querySelectorAll(
            '.training-course-subscription-form .partecipant',
        );

        for (let i = 0; i < partecipants.length; i++) {
            if (partecipants[i].classList.contains('is-hidden')) {
                partecipants[i].classList.remove('is-hidden');
                partecipants[i]
                    .querySelectorAll(
                        'input.input-email,input.input-name,input.input-surname',
                    )
                    .forEach((input) => {
                        input.setAttribute('required', true);
                    });
                break;
            }
        }
    });

    removePartecipantBtn.forEach((btn) => {
        btn.addEventListener('click', (e) => {
            e.preventDefault();
            const partecipant = e.currentTarget.closest('.partecipant');
            partecipant.classList.add('is-hidden');
            partecipant
                .querySelectorAll('input:not([type="hidden"])')
                .forEach((input) => {
                    input.value = '';
                    input.removeAttribute('checked');
                    input.removeAttribute('required');
                });
            partecipant.querySelector('textarea').value = '';
        });
    });
};

const initFileInputListener = () => {
    const fileInput = document.querySelector('input[type=file]');

    if (fileInput) {
        fileInput.onchange = () => {
            if (fileInput.files.length > 0) {
                const fileName = document.querySelector('.file-name');

                fileName.classList.remove('is-hidden');

                fileName.textContent = fileInput.files[0].name;
            }
        };
    }
};

const initMainSubscriberCompanyNameField = () => {
    const companyNameField = document.querySelector(
        '.training-course-subscription-form select[name="company_name"]',
    );

    if (!companyNameField) {
        return null;
    }

    const emailField = document.querySelector(
        '.training-course-subscription-form input[name="email"]',
    );
    const phoneField = document.querySelector(
        '.training-course-subscription-form input[name="phone"]',
    );

    companyNameField.addEventListener('change', (e) => {
        const selectedOption = e.target.options[e.target.selectedIndex];

        const email = selectedOption.dataset.email;
        const phone = selectedOption.dataset.phone;

        if (email && !emailField.value) {
            emailField.value = email;
        }

        if (phone && !phoneField.value) {
            phoneField.value = phone;
        }
    });
};

const initPartecipantCompanyNameField = (clientSelect) => {
    const pageData = window.__PAGE_DATA__;

    const clientChoices = new Choices(clientSelect, {
        placeholderValue: pageData.lang_clients_placeholder,
        shouldSort: false,
        searchFloor: 2,
        searchChoices: false,
        duplicateItemsAllowed: false,
        removeItemButton: true,
        noChoicesText: pageData.lang_no_result,
        itemSelectText: pageData.lang_click_to_select,
    });

    const lookupDelay = 100;
    let lookupTimeout = null;
    const lookupCache = {};

    const serverLookup = function() {
        const query = clientChoices.input.value;
        if (query in lookupCache) {
            populateOptions(lookupCache[query]);
        } else {
            const term = encodeURIComponent(query);

            const url = `/clients/list?term=${term}&with=users`;

            Axios.get(url).then(function(response) {
                if (response.data.data) {
                    const results = response.data.data.items.map((row) => {
                        return {
                            value: row.company_name,
                            label: `[${row.code}|${row.branch}] ${row.company_name}`,
                            customProperties: {
                                email:
                                    row.users && row.users.length ?
                                        row.users[0].email :
                                        null,
                                name:
                                    row.users && row.users.length ?
                                        row.users[0].name :
                                        null,
                                surname:
                                    row.users && row.users.length ?
                                        row.users[0].surname :
                                        null,
                            },
                        };
                    });
                    lookupCache[term] = results;
                    populateOptions(results);
                }
            });
        }
    };

    const populateOptions = function(options) {
        clientChoices.setChoices(options, 'value', 'label', true);
    };

    clientSelect.addEventListener('search', function(event) {
        clearTimeout(lookupTimeout);
        lookupTimeout = setTimeout(serverLookup, lookupDelay);
    });

    clientSelect.addEventListener('choice', function(event) {
        const selectedOption = event.detail.choice;
        const selectedOptionData = selectedOption.customProperties;
        const partecipantBlock = event.target.closest('.partecipant');

        if (partecipantBlock) {
            const nameField = partecipantBlock.querySelector('.input-name');
            nameField.value = selectedOptionData.name || '';

            const surnameField =
                partecipantBlock.querySelector('.input-surname');
            surnameField.value = selectedOptionData.surname || '';

            const emailField = partecipantBlock.querySelector('.input-email');
            emailField.value = selectedOptionData.email || '';
        }
    });
};

const initPartecipantsCompanyNameField = () => {
    const clientSelects = document.querySelectorAll('.js-choice-clients');

    if (!clientSelects || clientSelects.length === 0) {
        return null;
    }

    clientSelects.forEach((clientSelect) => {
        initPartecipantCompanyNameField(clientSelect);
    });
};

const initTrainingCourseSubscriptionForm = () => {
    initMainSubscriberCompanyNameField();
    initPartecipantsCompanyNameField();
};

export const attach = () => {
    document.addEventListener('DOMContentLoaded', function() {
        sectionToggler();
        initVimeoPlayers();
        initDragDrop();
        initTrainingCourse();
        initFileInputListener();
        initTrainingCourseSubscriptionForm();
    });
};
