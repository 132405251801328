import List from 'list.js';
import { filterableTable } from './filterable_table';

const initUserTable = () => {
    const options = {
        valueNames: ['id', 'email', 'name', 'surname', 'company_name', 'type', { name: 'url', attr: 'href' }],
        item: (values) => `
            <tr>
                <td class="id">${values.id}</td>
                <td class="email">${values.email}</td>                
                <td class="name">${values.name}</td>
                <td class="surname">${values.surname}</td>
                <td class="company_name">${values.company_name}</td>
                <td class="type">${values.type}</td>
                <td class="action has-text-right">
                    <a class="url" href="${values.url}">
                        <span class="icon"><i class="far fa-file"></i></span>
                    </a>
                </td>
            </tr>
        `,
    };

    const usersList = new List('users-table', options);

    const table = filterableTable(usersList, '/users/list');

    table.addFilter({
        name: 'term',
        className: 'user-search',
    });

    table.addFilter({
        name: 'type',
        className: 'type-search',
    });
};


document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('users-table')) {
        initUserTable();
    }
});
