import Axios from 'axios';
import { keys, template, first, reverse } from 'lodash';
import { deserializeObj, serializeObj } from '../utils/string';
import bulmaCalendar from 'bulma-calendar/dist/js/bulma-calendar.min.js';

const getEventByElement = (elem) => {
    return elem.tagName === 'SELECT' ? 'change' : 'keyup';
};

const reverseDate = (date) => reverse(date.split('-')).join('-');

const getJSON = (str, defaultValue = {}) => {
    try {
        return JSON.parse(str) || defaultValue;
    } catch (e) {
        return defaultValue;
    }
};

const persistedState = (key, initState = {}) => {
    const state = getJSON(localStorage.getItem(key), initState);

    const setState = (newState) => {
        localStorage.setItem(key, JSON.stringify(newState));
    };

    return [state, setState];
};

// support multiple tables
const setLoading = (loading) => {
    const tableContainers = document.querySelectorAll('.table-container');
    const loaders = document.querySelectorAll('.loader-wrapper');
    if (loading) {
        tableContainers.forEach((tableContainer) => {
            tableContainer.classList.add('is-hidden');
        });
        loaders.forEach((loader) => {
            loader.classList.remove('is-hidden');
        });
    } else {
        tableContainers.forEach((tableContainer) => {
            tableContainer.classList.remove('is-hidden');
        });
        loaders.forEach((loader) => {
            loader.classList.add('is-hidden');
        });
    }
};

export const filterableTable = (
    tableList,
    searchUri,
    { onUpdate = null, onResetRequestCompleted = null, onAddRow = null } = {},
    initState = { page: 1 },
    {
        nsPrefix = '',
    } = {},
) => {
    let [searchState, setSearchState] = persistedState(tableList.listContainer.id, initState);

    const filters = {};

    const emptyRow = document.querySelector(`.${nsPrefix}table-empty-row`);
    const filterablePagination = document.getElementById(`${nsPrefix}filterable-pagination`);
    const paginationString = document.getElementById(`${nsPrefix}pagination-template`).innerHTML;
    const paginationTemplate = template(paginationString);

    const updatePagination = ({ meta }) => {
        filterablePagination.innerHTML = paginationTemplate(meta);

        searchState.page = meta.current_page;
        setSearchState(searchState);

        document.querySelectorAll(`.${nsPrefix}ajax-pagination-link`)
            .forEach((elem) => elem.addEventListener('click', (e) => {
                e.preventDefault();
                const url = e.currentTarget.href;
                if (url) {
                    const search = url.match(/\?(.*)/);
                    if (search) {
                        const queryObj = deserializeObj(search[0]);
                        searchState = { ...searchState, ...queryObj };
                        processRequest();
                    }
                }
            }));
    };

    const processRequest = (showLoading = null) => {
        const url = `${searchUri}?${serializeObj(searchState)}`;

        if (showLoading) {
            setLoading(true);
        }

        return Axios.get(url)
            .then((response) => {
                tableList.clear();

                const results = response.data.data.items;

                if (onAddRow) {
                    results.map((row) => onAddRow(tableList, row));
                } else {
                    results.map((row) => tableList.add(row));
                }

                emptyRow.classList.toggle('is-hidden', results.length > 0);

                updatePagination(response.data);

                onUpdate && onUpdate(response.data, searchState);

                setLoading(false);

                return response.data;
            }).catch(() => {
                setLoading(false);
            });
    };

    const resetFilters = () => {
        keys(filters).map((key) => {
            searchState[key] = initState[key] ? initState[key] : '';

            if (filters[key] instanceof bulmaCalendar) {
                filters[key].clear(); // todo support init state
            } else {
                filters[key].value = initState[key] ? initState[key] : '';
            }
        });

        setSearchState(searchState);

        const request = processRequest();

        onResetRequestCompleted && onResetRequestCompleted(request, searchState, filters);
    };

    const addDateFilter = ({ name, className, options }) => {
        if (searchState[name]) {
            options.startDate = new Date(searchState[name]);
        }

        const calendars = bulmaCalendar.attach(`.${className}`, options);

        if (!calendars) return null;

        const calendar = first(calendars);

        calendar.on('clear', () => {
            searchState[name] = '';
            setSearchState(searchState);
            processRequest();
        });

        calendar.on('select', (bc) => {
            searchState = { ...searchState, [name]: reverseDate(bc.data.value()) };
            setSearchState(searchState);
            processRequest();
        });

        filters[name] = calendar;

        return calendar;
    };

    const addFilter = ({ name, className, onPrepareSearch = null, onSearchCompleted = null }) => {
        const element = document.querySelector(`.${className}`);

        if (!element) return null;

        element.value = searchState[name] || '';

        element.addEventListener(getEventByElement(element), (e) => {
            const value = e.target.value;

            searchState = { ...searchState, [name]: value };

            setSearchState(searchState);

            if (onPrepareSearch) {
                onPrepareSearch(e, searchState, filters);
            }

            const request = processRequest();

            if (onSearchCompleted) {
                onSearchCompleted(request, searchState, filters);
            }
        });

        filters[name] = element;

        return element;
    };

    document.querySelector(`.${nsPrefix}table-reset-filters`).addEventListener('click', resetFilters);

    // init call
    processRequest(true);

    return {
        addFilter,
        addDateFilter,
    };
};


