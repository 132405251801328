

export const formatCurrency = (number, digits = 2, currency = 'EUR') => {
    if (Intl.NumberFormat) {
        return new Intl.NumberFormat('it-IT', {
            style: 'currency', currency: currency, minimumFractionDigits: digits, maximumFractionDigits: digits,
        }).format(number);
    }

    return `${(Math.round(number * 100) / 100).toFixed(digits).replace('.', ',')} €`;
};
