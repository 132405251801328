import BulmaModal from './bulma_modal';
import { attachValidationToForm } from './validation';

const paymentDeadlineModalElem = document.querySelector('#send-payment-deadline-modal');

if (paymentDeadlineModalElem) {
    const paymentDeadlineModal = new BulmaModal(paymentDeadlineModalElem);

    document.querySelectorAll('.send-payment-deadline').forEach(function(button) {
        button.addEventListener('click', function(e) {
            const element = e.currentTarget;
            e.preventDefault();
            const form = paymentDeadlineModal.element.querySelector('form');
            attachValidationToForm(form);
            form.action = element.dataset.action;

            paymentDeadlineModal.element.querySelector('form input[name="deadline"]').value =
                element.dataset.reference;
            paymentDeadlineModal.show();
        });
    });
}
