import bulmaCalendar from 'bulma-calendar/dist/js/bulma-calendar.min.js';

const attachCalendar = () => {
    bulmaCalendar.attach('.bulma-calendar[type="date"]', {
        type: 'date',
        dateFormat: 'dd-MM-yyyy',
        showFooter: false,
    });

    bulmaCalendar.attach('.bulma-calendar[type="time"]', {
        type: 'time',
        startTime: '10:00',
        timeFormat: 'HH:mm',
        showFooter: true,
        validateLabel: 'OK',
        showClearButton: false,
    });
};

export const attach = () => {
    document.addEventListener('DOMContentLoaded', function() {
        attachCalendar();
    });
};
