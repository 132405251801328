import List from 'list.js';
import { filterableTable } from './filterable_table';

const tableId = 'training-courses-public-subscribers-table';

const initTrainingCourseTable = (courseId) => {
    const options = {
        valueNames: [
            'name',
            'surname',
            'user',
            'company_name',
            'email',
            'phone',
            'subscribed_by',
            'created_at',
            { name: 'delete_url', attr: 'href' },
        ],
        item: (values) => `
            <tr>                
                <td class="name"></td>                
                <td class="surname"></td>
                <td class="user"></td>
                <td class="company_name"></td>
                <td class="email"></td>
                <td class="phone"></td>
                <td class="subscribed_by"></td>
                <td class="created_at"></td>                
            </tr>
        `,
    };

    const list = new List(tableId, options);

    const table = filterableTable(
        list,
        `/mycfe/training-courses/${courseId}/subscribers/list`,
    );

    table.addFilter({
        name: 'term',
        className: 'training-course-search',
    });
};

document.addEventListener('DOMContentLoaded', () => {
    const tableElem = document.getElementById(tableId);
    if (tableElem) {
        const courseId = tableElem.dataset.id;
        if (courseId) {
            initTrainingCourseTable(courseId);
        }
    }
});
