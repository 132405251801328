function hideOnClickOutside(element, cb = null) {
    const outsideClickListener = (event) => {
        if (!element.contains(event.target) && isVisible(element)) {
            cb ? cb(element) : element.style.display = 'none';
            removeClickListener();
        }
    };

    const removeClickListener = () => {
        document.removeEventListener('click', outsideClickListener);
    };

    document.addEventListener('click', outsideClickListener);
}

const isVisible = (elem) =>
    !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

const popoverToggler = () => {
    document.querySelectorAll('.popover-trigger').forEach((el) => {
        el.addEventListener('click', (ev) => {
            ev.currentTarget.parentElement.classList.toggle('is-popover-active');

            hideOnClickOutside(el.parentElement, (el) => {
                el.classList.remove('is-popover-active');
            });
        });
    });

    document.querySelectorAll('.popover-trigger-close').forEach((el) => {
        el.addEventListener('click', (ev) => {
            ev.preventDefault();
            document.querySelectorAll('.popover').forEach((el) => el.classList.remove('is-popover-active'));
        });
    });
};


export const attach = () => {
    document.addEventListener('DOMContentLoaded', function() {
        popoverToggler();
    });
};
