import BulmaModal from './bulma_modal';
import { attachValidationToForm } from './validation';

const cartGroupReferenceModalElement = document.querySelector('#cart-group-reference-modal');

if (cartGroupReferenceModalElement) {
    const cartGroupReferenceModal = new BulmaModal(cartGroupReferenceModalElement);

    document.querySelectorAll('#cart .cart-group-reference').forEach(function(button) {
        button.addEventListener('click', function(e) {
            const element = e.currentTarget;
            e.preventDefault();
            const form = cartGroupReferenceModal.element.querySelector('form');
            attachValidationToForm(form);
            form.action = element.dataset.action;

            cartGroupReferenceModal.element.querySelector('form input[name="reference"]').value =
                element.dataset.reference;
            cartGroupReferenceModal.show();
        });
    });
}

const cartNoteModalElement = document.querySelector('#cart-notes-modal');

if (cartNoteModalElement) {
    const cartNoteModal = new BulmaModal(cartNoteModalElement);

    document.querySelectorAll('.cart-notes').forEach(function(button) {
        button.addEventListener('click', function(e) {
            e.preventDefault();
            const element = e.currentTarget;
            const form = cartNoteModal.element.querySelector('form');
            form.action = element.dataset.action;
            cartNoteModal.element.querySelector('form textarea[name="notes"]').value = element.dataset.notes;
            cartNoteModal.show();
        });
    });
}

const cartOrderModalElement = document.querySelector('#cart-order-modal');
if (cartOrderModalElement) {
    const cartOrderModal = new BulmaModal(cartOrderModalElement);
    document.querySelectorAll('.cart-order').forEach(function(button) {
        button.addEventListener('click', function(e) {
            e.preventDefault();
            cartOrderModal.show();
        });
    });
}

document.querySelectorAll('#cart .cart-quantity').forEach(function(group) {
    const quantityValue = group.querySelector('.cart-quantity-value');
    const formQuantityValue = group.querySelector('.edit-quantity-inline input[name="quantity"]');
    const editableQuantity = group.querySelector('[name="quantity"]');
    const step = parseInt(group.querySelector('[name="step"]').value);

    group.querySelector('.cart-quantity-value').addEventListener('click', function() {
        toggleCartGroupQuantitySaveButtons(false);
    });

    group.querySelector('.cart-quantity-plus').addEventListener('click', function(e) {
        e.preventDefault();
        let currentValue = parseInt(quantityValue.textContent.trim());
        const originalValue = parseInt(quantityValue.value.trim());
        currentValue += step;
        quantityValue.textContent = currentValue.toString();
        formQuantityValue.value = currentValue;
        editableQuantity.value = currentValue;
        toggleCartGroupQuantitySaveButtons(originalValue !== currentValue);
    });

    group.querySelector('.cart-quantity-minus').addEventListener('click', function(e) {
        e.preventDefault();
        let currentValue = parseInt(quantityValue.textContent.trim());
        const originalValue = parseInt(quantityValue.value.trim());
        if (currentValue < 2 * step) {
            return;
        }
        currentValue -= step;
        quantityValue.textContent = currentValue.toString();
        formQuantityValue.value = currentValue;
        editableQuantity.value = currentValue;
        toggleCartGroupQuantitySaveButtons(originalValue !== currentValue);
    });

    group.querySelector('.cart-quantity-cancel').addEventListener('click', function(e) {
        e.preventDefault();
        const originalValue = parseInt(quantityValue.value.trim());
        quantityValue.textContent = originalValue.toString();
        editableQuantity.value = originalValue;
        toggleCartGroupQuantitySaveButtons(false);
    });

    function toggleCartGroupQuantitySaveButtons(visible) {
        group.querySelectorAll('.cart-quantity-save, .cart-quantity-cancel')
            .forEach(function(button) {
                if (visible) {
                    button.classList.remove('is-hidden');
                } else {
                    button.classList.add('is-hidden');
                }
            });
    }
});

document.querySelectorAll('#cart .delivery-time-select').forEach(function(select) {
    select.addEventListener('change', function(e) {
        const element = e.target;
        const value = element.value;
        const initValue = select.dataset.initValue;
        const actionWrapper = element.closest('form').querySelector('.delivery-time-actions');
        actionWrapper.classList.toggle('is-hidden', value === initValue);
    });
});


document.querySelectorAll('#cart .cart-group-time-cancel').forEach(function(button) {
    button.addEventListener('click', function(e) {
        e.preventDefault();
        const element = e.target;
        const select = element.closest('form').querySelector('.delivery-time-select');
        select.value = select.dataset.initValue;
        const actionWrapper = element.closest('form').querySelector('.delivery-time-actions');
        actionWrapper.classList.add('is-hidden');
    });
});

document.querySelectorAll('#cart .cart-group-toggler').forEach(function(elem) {
    elem.addEventListener('click', function(e) {
        e.preventDefault();
        const dataset = elem.dataset;
        document.getElementById(`cart-group-${dataset.groupId}`).classList.toggle('is-compact');
    });
});
