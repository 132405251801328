import List from 'list.js';
import { first } from 'lodash';
import { filterableTable } from './filterable_table';

const updateProvinceValues = (request, searchState, filters) => {
    const searchByProvinceSelect = filters.province_id;

    request
        .then(({ data }) => {
            const firstOption = first(searchByProvinceSelect.getElementsByTagName('option'));
            searchByProvinceSelect.innerHTML = '';
            searchByProvinceSelect.appendChild(firstOption);

            data.filters.provinces.forEach((province) => {
                const option = document.createElement('option');
                option.innerText = `${province.description} (${province.code})`;
                option.value = province.id;
                option.selected = parseInt(searchState.province_id) === parseInt(province.id);
                searchByProvinceSelect.append(option);
            });
        });
};

const initClientTable = () => {
    const options = {
        valueNames: [
            'id',
            'branch',
            'code',
            'company_name',
            'full_address',
            'agent',
            { name: 'profile_url', attr: 'href' },
        ],
        item: (values) => `
            <tr>
                <td class="code" style="${!values.active ? 'text-decoration: line-through;' : ''}">${values.code}</td>
                <td class="branch" style="${!values.active ? 'text-decoration: line-through;' : ''}">
                    ${values.branch}
                </td>
                <td class="company_name">${values.company_name}</td>
                <td class="full_address">${values.full_address}</td>
                <td class="agent">${values.agent}</td>
                <td class="action has-text-right">
                    <a class="profile_url" href="${values.profile_url}">
                        <span class="icon"><i class="far fa-file"></i></span>
                    </a>
                </td>
            </tr>
        `,
    };

    const clientsList = new List('clients-table', options);

    const table = filterableTable(clientsList, '/clients/list', {
        onResetRequestCompleted: updateProvinceValues,
    });

    table.addFilter({
        name: 'term',
        className: 'client-search',
    });

    table.addFilter({
        name: 'agent_id',
        className: 'agent-search',
        onPrepareSearch: (e, searchState, filters) => {
            const searchByProvinceSelect = filters.province_id;

            if (searchState.province_id && searchState.agent_id) {
                searchState.province_id = '';
                searchByProvinceSelect.value = '';
            }
        },
        onSearchCompleted: updateProvinceValues,
    });

    table.addFilter({
        name: 'province_id',
        className: 'province-search',
    });
};


document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('clients-table')) {
        initClientTable();
    }
});
