
import Glide from '@glidejs/glide';


export const attach = () => {
    document.addEventListener('DOMContentLoaded', function() {
        document.querySelector('.glide') &&
            new Glide('.glide', { autoplay: 4000 }).mount();
    });
};
